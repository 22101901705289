import React, { useEffect, useState } from 'react'
import Button from '../button/button'
import { PromptResponseType, PromptResponseValue } from '../../types'

export interface TextResponseType extends PromptResponseType {
  type: 'text'
}

type TextResponseProps<T> = {
  response: TextResponseType
  context: T
  submitResponse: (response: PromptResponseValue) => void
}

function TextResponse<T>({
  response,
  context,
  submitResponse,
}: TextResponseProps<T>) {
  const [value, setValue] = useState<string>(
    context[response.value ?? ''] ?? ''
  )
  useEffect(() => {
    if (context[response.value] && context[response.value] !== value)
      setValue(context[response.value])
  }, [context])
  return (
    <>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) =>
          e.key === 'Enter' && submitResponse({ value, display: value })
        }
        tabIndex={1}
        autoFocus
        style={{ maxWidth: '300px', margin: 10 }}
      />
      <Button
        tabIndex={2}
        onClick={(e) => submitResponse({ value, display: value })}
      >
        Submit
      </Button>
    </>
  )
}

export default TextResponse
