import { ProcessStep } from '../types.d'

export function Process<T extends Object>({
  context,
  step,
}: {
  context: T
  step: ProcessStep
}) {
  const updates = {}
  const contextKeys = Object.keys(context).join(',')
  Object.keys(step.variable).forEach((k) => {
    updates[k] = ProcessRawJS({ context, raw: step.variable[k], contextKeys })
  })
  // console.log(updates)
  return updates
}

export function ProcessRawJS<T extends Object>({
  context,
  raw,
  contextKeys,
}: {
  context: T
  raw: string
  contextKeys?: string
}) {
  contextKeys = contextKeys ?? Object.keys(context).join(',')
  const func = new Function(
    'context',
    `
                const {${contextKeys}} = context
                return ${raw}
            `
  )
  // console.log(func.toString())
  return func(context)
}
