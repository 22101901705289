import React, { useEffect, useState } from 'react'
import FormPrompt from './components/prompt'
import ChatView from './components/chatView'
import { editStep, nextStep } from './promptService'
import {
  FormPromptProps,
  StackRecord,
  Prompt,
  PromptResponseValue,
} from './types'

function FormPromptWrapper<T extends Object>({
  context,
  prompts,
  updateContext,
}: FormPromptProps<T>) {
  const [stack, setStack] = useState<StackRecord<T>[]>([])
  const [loadedPrompts, setLoadedPrompts] = useState<Prompt[]>([])
  const [isComplete, setIsComplete] = useState(false)

  const submitResponse = (
    key: string,
    response: PromptResponseValue | PromptResponseValue[]
  ) => {
    const newStack = [...stack]
    const record = { ...newStack[newStack.length - 1] }
    record.result = { response }
    newStack[newStack.length - 1] = record
    const newContext = {
      ...context,
      [key]: Array.isArray(response) ? response : response.value,
    }
    console.log(key, response)
    process(nextStep({ stack: newStack, context: newContext, prompts }))
  }

  const process = (
    [stack, records, context, contextUpdates],
    resetComplete?: boolean
  ) => {
    setLoadedPrompts(
      records
        .filter((r) => r.step.type == 'prompt' || r.step.type == 'complete')
        .map((r) => r.step) as Prompt[]
    )
    updateContext({ ...context, ...contextUpdates })
    setStack([...stack, ...records])
    if (resetComplete) setIsComplete(false)
  }
  useEffect(() => {
    process(
      nextStep({
        stack,
        context,
        prompts,
      })
    )
  }, [])
  const onEdit = (location: number) => {
    process(
      editStep({
        stack: stack,
        context: context,
        prompts,
        location,
      }),
      true
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          borderRight: '1px solid #808080',
          maxWidth: '33%',
          overflow: 'hidden',
        }}
      >
        <ChatView stack={stack} onEdit={onEdit} />
      </div>
      <FormPrompt
        context={context}
        loadedPrompts={loadedPrompts}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        submitResponse={submitResponse}
      />
    </div>
  )
}
export default FormPromptWrapper
