export const selectRef = (text) => {
  var doc = document,
    range,
    selection
  //@ts-ignore
  if (doc.body.createTextRange) {
    //@ts-ignore
    range = document.body.createTextRange()
    range.moveToElementText(text)
    range.select()
  } else if (window.getSelection) {
    selection = window.getSelection()
    range = document.createRange()
    range.selectNodeContents(text)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}
