import React from 'react'
import { SignatureData, baseTextStyle } from '..'
import { SignatureComponent } from '..'
import { getWebUrl } from '../utils'
import { Link } from './link'

export const WebLink: SignatureComponent = ({ type }) => {
  const display = getWebUrl(type)
  const url = `https://www.${getWebUrl(type)}`
  return <Link url={url}>{display}</Link>
}
