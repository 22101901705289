import React from 'react'
import { ButtonProps } from 'theme-ui'
import './button.css'
const Button: React.FC<
  ButtonProps & {
    buttonRef?: any
    onEnter?: () => void
    onSpacebar?: () => void
  }
> = (props) => {
  return (
    <button
      {...{
        ...Object.keys(props).reduce(
          (r, c) =>
            !['buttonRef', 'onEnter', 'onSpacebar'].includes(c)
              ? { ...r, [c]: props[c] }
              : r,
          {}
        ),
        style: {
          ...{
            border: '1px solid #808080',
            padding: 10,

            cursor: 'pointer',
            borderRadius: 10,
            background: '#419995',
            color: 'white',
          },
          ...(props.style ?? {}),
        },
        ref: props.buttonRef,
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            props.onEnter?.apply(null)
          } else if (e.key === ' ') {
            props.onSpacebar?.apply(null)
          }
        },
      }}
      className="email-button"
    />
  )
}
export default Button
