import React, { useEffect, useRef, useState } from 'react'
import Button from '../button/button'
import { PromptResponseType, PromptResponseValue } from '../../types'
export interface MultiSelectResponseType extends PromptResponseType {
  type: 'multi'
  options: PromptResponseValue[]
}

type MultiSelectResponseProps<T> = {
  response: MultiSelectResponseType
  context: T
  submitResponse: (response: PromptResponseValue[]) => void
}

function getOptionValuesFromContext<T>(
  context: T,
  response: MultiSelectResponseType
) {
  if (!context[response.value]) return response.options.filter((o) => o.default)
  return (
    response.options?.filter((o) =>
      (context[response.value ?? ''] || []).includes(o.value)
    ) || []
  )
}

function MultiSelectResponse<T>({
  response,
  context,
  submitResponse,
}: MultiSelectResponseProps<T>) {
  const [values, setValues] = useState<PromptResponseValue[] | undefined>(
    getOptionValuesFromContext(context, response)
  )
  const firstOption = useRef<HTMLDivElement>()
  /*useEffect(() => {
    if (
      response.selected &&
      context[response.selected.value] &&
      context[response.selected.value] !== value
    )
      setValue(getOptionValueFromContext(context, response))
  }, [context])*/
  const toggleOption = (option) => {
    const newValues = [...(values ?? [])]
    const ix = newValues?.findIndex((v) => v.value == option.value) ?? -1
    if (~ix) {
      newValues.splice(ix, 1)
    } else {
      newValues?.push(option)
    }
    setValues(newValues)
  }
  useEffect(() => {
    if (firstOption.current) {
      firstOption.current.focus()
    }
  }, [firstOption])
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {response.options.map((o, ix) => {
        const ref = ix == 0 ? { buttonRef: firstOption as any } : {}
        const selected = !!values?.find((v) => v.value == o.value)
        return (
          <Button
            {...ref}
            key={o.value}
            tabIndex={ix + 1}
            style={{
              background: !!selected ? '#C7D643' : 'transparent',
              margin: 5,
              fontSize: 12,
              color: 'inherit',
            }}
            onEnter={() => submitResponse(values ?? [])}
            onClick={() => toggleOption(o)}
            className="selectOption"
          >
            <span
              style={{
                width: '10px',
                fontSize: 14,
                fontWeight: 'bold',
                color: 'green',
                marginRight: 5,
              }}
            >
              {!!selected ? <>&#x2713;</> : ' '}
            </span>
            {o.display}
          </Button>
        )
      })}
      <Button
        style={{ margin: 5 }}
        tabIndex={response.options.length + 1}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitResponse(values ?? [])
          }
        }}
        onClick={() => submitResponse(values ?? [])}
      >
        Submit
      </Button>
    </div>
  )
}

export default MultiSelectResponse
