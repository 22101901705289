import React from 'react'
import { baseTextStyle } from '..'

export const Link: React.FC<{ url: string; children: React.ReactNode }> = ({
  url,
  children,
}) => {
  return (
    <a href={url} style={{ ...baseTextStyle }}>
      <span
        style={{
          ...baseTextStyle,
          whiteSpace: 'pre',
          textDecoration: 'underline',
        }}
      >
        {children}
      </span>
    </a>
  )
}
