import React, { useState } from 'react'
import './email.css'
import FormPrompt from '../formprompt'
import prompts from './prompts.json'
import { PromptStep } from '../formprompt/types'

const FormPromptWrapper = () => {
  const [context, updateContext] = useState({})
  return (
    <FormPrompt
      context={context}
      updateContext={updateContext}
      prompts={prompts as PromptStep[]}
    />
  )
}

export default FormPromptWrapper
