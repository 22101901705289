import React, { useEffect, useRef, useState } from 'react'
import Button from '../button/button'
import { PromptResponseType, PromptResponseValue } from '../../types'
export interface SelectResponseType extends PromptResponseType {
  type: 'select'
  options: PromptResponseValue[]
}

type SelectResponseProps<T> = {
  response: SelectResponseType
  context: T
  submitResponse: (response: PromptResponseValue) => void
}

// function getOptionValueFromContext<T>(
//   context: T,
//   response: SelectResponseType
// ) {
//   if (!response.selected?.value || !context[response.selected.value])
//     return undefined
//   return response.options?.find(
//     (o) => o.value == context[response.selected?.value ?? '']
//   )
// }

function SelectResponse<T>({
  response,
  context,
  submitResponse,
}: SelectResponseProps<T>) {
  // const [value, setValue] = useState<PromptResponseValue | undefined>(
  //   getOptionValueFromContext(context, response)
  // )
  const firstOption = useRef<HTMLDivElement>()
  // useEffect(() => {
  //   if (
  //     response.selected &&
  //     context[response.selected.value] &&
  //     context[response.selected.value] !== value
  //   )
  //     setValue(getOptionValueFromContext(context, response))
  // }, [context])
  useEffect(() => {
    if (firstOption.current) {
      firstOption.current.focus()
    }
  }, [firstOption])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {response.options.map((o, ix) => {
        const ref = ix == 0 ? { buttonRef: firstOption as any } : {}
        return (
          <Button
            {...ref}
            key={o.display}
            tabIndex={ix + 1}
            style={{
              margin: 5,
              fontSize: 12,
              color: 'inherit',
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onClick={() => submitResponse(o)}
            className="selectOption"
          >
            {!!o.imageUrl && (
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 5,
                }}
              >
                <img src={o.imageUrl} width={100} />
              </div>
            )}
            {o.display}
          </Button>
        )
      })}
    </div>
  )
}

export default SelectResponse
