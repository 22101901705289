import React, { Fragment, useEffect, useRef, useState } from 'react'
import { StackRecord } from '../types'

const ChatBox: React.FC<{
  type: 'response' | 'bot' | 'request'
  value: string
  onEdit?: () => void
}> = ({ type, value, onEdit }) => {
  return (
    <div
      style={{
        alignSelf: type == 'bot' ? 'flex-start' : 'flex-end',
        maxWidth: '66%',
        margin: 5,
        marginLeft: 10,
        flexShrink: 1,
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          background: type == 'bot' ? 'lightgray' : 'blue',
          color: type == 'bot' ? 'black' : 'white',
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 15,
          border: `1px solid ${type == 'bot' ? 'gray' : 'darkblue'}`,
          fontSize: '14px',
          fontFamily: 'Helvetica',
        }}
      >
        {value}
      </div>
      {type == 'response' && (
        <div
          style={{
            fontSize: 10,
            color: '#555',
            marginLeft: '5px',
            cursor: 'pointer',
          }}
          onClick={onEdit}
        >
          Edit
        </div>
      )}
    </div>
  )
}

function ChatView<T>({
  stack,
  onEdit,
}: {
  stack: StackRecord<T>[]
  onEdit: (location: number) => void
}) {
  const container = useRef<HTMLDivElement>(null)
  const chatEndRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    //if (shouldScroll)
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    //console.log('stack changed, ', shouldScroll)
  }, [stack])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto',
      }}
      ref={container}
    >
      {stack
        .filter((r) => r.step.type == 'prompt' || r.step.type == 'request')
        .map((record, ix) => {
          const step = record.step
          const response = record.result?.response

          return (
            <Fragment key={`${record.location}-${ix}`}>
              {step.type == 'prompt' ? (
                <ChatBox value={step.prompt ?? ''} type="bot" />
              ) : (
                step.type == 'request' && (
                  <ChatBox value={step.request ?? ''} type="request" />
                )
              )}
              {!!response && (
                <ChatBox
                  value={
                    Array.isArray(response)
                      ? !response.length
                        ? 'None'
                        : response.map((r) => r.display).join(', ')
                      : response?.display ?? ''
                  }
                  onEdit={() => {
                    onEdit(record.location)
                  }}
                  type="response"
                />
              )}
            </Fragment>
          )
        })}
      <div ref={chatEndRef} />
    </div>
  )
}

export default ChatView
