import React, { useEffect, useRef, useState } from 'react'
import { PromptView } from './promptView'
import Signature from '../../../email/signature'
import Button from '../button/button'
import { Prompt, PromptViewProps } from '../../types'
import { selectRef } from '../../dom-utils'

function FormPrompt<T extends Object>({
  context,
  loadedPrompts,
  isComplete,
  setIsComplete,
  submitResponse,
}: PromptViewProps<T>) {
  const [activePrompts, setActivePrompts] = useState<Prompt[]>([])
  const [copied, setCopied] = useState(false)
  const ref = useRef<React.LegacyRef<HTMLTableElement>>()
  useEffect(() => {
    if (loadedPrompts?.length) setActivePrompts([loadedPrompts[0]])
  }, [loadedPrompts])

  const showNextPrompt = () => {
    if (loadedPrompts?.length > activePrompts?.length) {
      console.log({ loadedPrompts })
      setActivePrompts([...activePrompts, loadedPrompts[activePrompts.length]])
      if ((loadedPrompts[activePrompts.length] as any).type == 'complete')
        setIsComplete(true)
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          flexShrink: 1,
          height: 65,
          width: '100%',
          borderBottom: '1px solid #EEE',
          textAlign: 'center',
        }}
      >
        <img src="/images/Curo.png" width={200} />
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexGrow: 1,
          //justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {activePrompts
          ?.filter((p) => p.type == 'prompt')
          .map((step, ix) => (
            <PromptView
              context={context}
              submitResponse={submitResponse}
              prompt={step as Prompt}
              key={step.prompt}
              complete={showNextPrompt}
            />
          ))}

        {!!isComplete && (
          <>
            <div
              style={{
                textAlign: 'left',
                margin: 20,
                padding: 5,
                border: '1px solid #CCC',
              }}
            >
              <Signature {...(context as any)} signatureRef={ref} />
            </div>

            <Button
              style={{}}
              onClick={(e) => {
                ref.current &&
                  typeof ref.current !== 'string' &&
                  selectRef(ref.current)
                document.execCommand('copy')
                ;(window.getSelection
                  ? window.getSelection()
                  : (document as any).selection
                ).empty()
                setCopied(true)
              }}
            >
              Copy to Clipboard
            </Button>
            {!!copied && (
              <div style={{ margin: 10 }}>
                Copied! Use the Edit buttons in the chat view on the left to
                make changes or create additional signatures.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
export default FormPrompt
