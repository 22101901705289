import React, { useState } from 'react'
import TextResponse from '../inputs/textResponse'
import SelectResponse from '../inputs/selectResponse'
import { TypeAnimation } from 'react-type-animation'
import MultiSelectResponse from '../inputs/multiselectResponse'
import { PromptResponseValue, Prompt } from '../../types'
import ConditionalResponse from '../inputs/conditionalResponse'
import TypeheadResponse from '../inputs/typeheadResponse'

type PromptProps<T> = {
  context: T
  submitResponse: (
    key: string,
    value: PromptResponseValue | PromptResponseValue[]
  ) => void
  prompt: Prompt
  complete: () => void
}

export function PromptView<T>({
  context,
  prompt,
  submitResponse,
  complete,
}: PromptProps<T>) {
  const [showResponseOptions, setShowResponseOptions] = useState(false)
  return (
    <>
      <div
        style={{
          display: 'block',
          textAlign: 'center',
          margin: '10px 70px 20px',
        }}
      >
        <TypeAnimation
          sequence={[
            prompt.prompt,
            400,
            () => {
              complete()
              setShowResponseOptions(true)
            },
          ]}
          speed={70}
          wrapper="span"
          cursor={false}
          style={{ fontSize: '2em', display: 'inline-block' }}
        />
      </div>
      {!!prompt.response && showResponseOptions && (
        <>
          {prompt.response.type == 'text' ? (
            <TextResponse
              context={context}
              submitResponse={(value: PromptResponseValue) =>
                submitResponse(prompt.response?.value ?? '', value)
              }
              response={prompt.response}
            />
          ) : prompt.response.type == 'select' ? (
            <SelectResponse
              context={context}
              submitResponse={(value: PromptResponseValue) =>
                submitResponse(prompt.response?.value ?? '', value)
              }
              response={prompt.response}
            />
          ) : prompt.response.type == 'typehead' ? (
            <TypeheadResponse
              context={context}
              submitResponse={(value: PromptResponseValue) =>
                submitResponse(prompt.response?.value ?? '', value)
              }
              response={prompt.response}
            />
          ) : prompt.response.type == 'multi' ? (
            <MultiSelectResponse
              context={context}
              submitResponse={(value: PromptResponseValue[]) =>
                submitResponse(prompt.response?.value ?? '', value)
              }
              response={prompt.response}
            />
          ) : (
            <pre style={{ whiteSpace: 'pre' }}>
              {JSON.stringify(prompt.response, null, 2)}
            </pre>
          )}
        </>
      )}
    </>
  )
}
