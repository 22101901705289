import { StackRecord, PromptStep, Prompt } from '../types.d'
import { Process, ProcessRawJS } from './processor'

export function editStep<T>({
  stack,
  context,
  prompts,
  location,
}: {
  stack: StackRecord<T>[]
  context: any
  prompts: PromptStep[]
  location: number
}) {
  const processed: StackRecord<T>[] = []
  let contextUpdates: { [key: string]: string } = {}
  const original = prompts[location] as Prompt
  const step: PromptStep = {
    type: 'request',
    request: `Whoops - I'd like to change to ${
      original.editable.type == 'default'
        ? `my ${original.editable.display}`
        : original.editable.display
    }.`,
  }
  let record: StackRecord<T> = { location: -1, context, step }
  processed.push(record)
  processed.push({ location, step: prompts[location], context })
  return [stack, processed, context, contextUpdates] as [
    StackRecord<T>[],
    StackRecord<T>[],
    T,
    { [key: string]: string }
  ]
}

export function nextStep<T>({
  stack,
  context,
  prompts,
}: {
  stack: StackRecord<T>[]
  context: any
  prompts: PromptStep[]
}) {
  let prevRecord = stack.length ? stack[stack.length - 1] : null
  let location = prevRecord ? prevRecord.location + 1 : 0
  const processed: StackRecord<T>[] = []
  let contextUpdates: { [key: string]: string } = {}
  let stop = true
  do {
    const step = location < prompts.length ? { ...prompts[location] } : null
    if (!step) {
      processed.push({ location, context, step: { type: 'complete' } })
      break
    }
    let record: StackRecord<T> = { location, context, step }

    stop = true
    if (step?.type == 'prompt') {
      let parse = true
      if (step.rule) {
        parse = ProcessRawJS({ context, raw: step.rule.raw })
      }
      if (parse) {
        step.prompt = parsePrompt({ context, prompt: step.prompt })
        stop = !!step.response
        processed.push(record)
      } else {
        stop = false
      }
    } else if (step?.type == 'process') {
      contextUpdates = { ...contextUpdates, ...Process({ context, step }) }
      context = { ...context, ...contextUpdates }
      stop = false
      processed.push(record)
    }
    location++
  } while (!stop)
  return [stack, processed, context, contextUpdates] as [
    StackRecord<T>[],
    StackRecord<T>[],
    T,
    { [key: string]: string }
  ]
}

export function parsePrompt<T>({
  context,
  prompt,
}: {
  context: T
  prompt: string
}) {
  const regex = /{([^{}]*)}/g
  return prompt.replace(regex, (match, key) => {
    const value = context[key.trim()]
    return value !== undefined ? String(value) : match
  })
}
