import { SignatureData } from './signature'
import addresses from './addresses.json'
import prompts from '../prompts.json'

export const Brands = prompts.find(
  (p) => p.type == 'prompt' && p.response?.value == 'type'
)?.response?.options as {
  value: string
  imageUrl: string
  webUrl: string
  display: string
}[]

export const BrandLogos = Brands?.reduce(
  (r, o) => ({ ...r, [o.value]: o.imageUrl }),
  {}
)

export const stripPhoneNumber = (number?: string) =>
  (number ?? '').replace(/\D/g, '')

export const formatPhoneNumber = (number?: string) => {
  const stripped = stripPhoneNumber(number)
  return `${stripped.substring(0, 3)}.${stripped.substring(
    3,
    6
  )}.${stripped.substring(6)}`
}
export const createPhoneLine = (number?: string) =>
  `tel:${stripPhoneNumber(number)}`

export const getLogo = (type: SignatureData['type']) =>
  Brands.find((b) => b.value == type)?.imageUrl ?? '/images/Curo.png'

export const getWebUrl = (type: SignatureData['type']) =>
  Brands.find((b) => b.value == type)?.webUrl ?? 'curo.com'

export const lookupAddress = (type: SignatureData['type'], name: any) => {
  // @ts-ignore
  return addresses[type][name] ?? ''
}
