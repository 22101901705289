import React from 'react'
import { SignatureComponent } from '.'

export const BrandLogos: SignatureComponent = ({ type }) => {
  const imgUrl =
    type == 'CURO Corporate'
      ? '/images/BrandLogos_Horiz.png'
      : '/images/Curo_PlusBrandLogos_Horiz.png'
  return <img src={imgUrl} width={500} />
}
