import React from 'react'
import {
  ComponentTypes,
  SignatureComponent,
  SignatureData,
  baseTextStyle,
} from '..'
import { MobileNumber, TelephoneNumber } from './numberLink'
import { WebLink } from './webLink'
import { EmailLink } from './emailLink'
import { FaxNumber } from './faxNumber'
export * from '../signature.d'
export const componentLookup: {
  [key in keyof ComponentTypes]: SignatureComponent
} = {
  mobile: MobileNumber,
  telephone: TelephoneNumber,
  email: EmailLink,
  fax: FaxNumber,
  web: WebLink,
}

export const Component: React.FC<{
  data: SignatureData
  type: keyof ComponentTypes
}> = ({ data, type }) => {
  const Component = componentLookup[type]

  if (!data[type] || !Component) return <></>
  return (
    <tr style={{ border: 'none', borderBottom: '0px', padding: 0, margin: 0 }}>
      <td style={{ ...baseTextStyle, fontWeight: 'bold', padding: 0 }}>
        <span style={{ ...baseTextStyle }}>
          {type.substring(0, 1).toLowerCase()}:
        </span>
      </td>
      <td
        style={{
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: '4px',
        }}
      >
        <span style={{ ...baseTextStyle }}>
          <Component {...data} />
        </span>
      </td>
    </tr>
  )
}
export const Components: React.FC<SignatureData> = (data) => {
  return (
    <table
      style={{
        border: 'none',
        borderBottom: '0px',
        padding: 0,
        margin: 0,
        width: 'auto',
      }}
    >
      <tbody
        style={{ border: 'none', borderBottom: '0px', padding: 0, margin: 0 }}
      >
        {Object.keys(componentLookup).map((k) => (
          <Component data={data} type={k as keyof ComponentTypes} key={k} />
        ))}
      </tbody>
    </table>
  )
}
export default Components
