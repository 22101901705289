import React from 'react'
import { SignatureComponent } from '.'
import { baseTextStyle } from '..'

export const TitleComponent: SignatureComponent = ({ title }) => {
  return (
    <span
      style={{
        ...baseTextStyle,
      }}
    >
      {title}
    </span>
  )
}
