import React from 'react'
import { createPhoneLine, formatPhoneNumber } from '../utils'
import { SignatureComponent } from '.'
import { Link } from './link'

const PhoneNumber: React.FC<{ number?: string }> = ({ number }) => {
  const link = createPhoneLine(number)
  const formatted = formatPhoneNumber(number)
  return <Link url={link}>{formatted}</Link>
}

export const TelephoneNumber: SignatureComponent = ({ telephone }) => (
  <PhoneNumber number={telephone}></PhoneNumber>
)
export const MobileNumber: SignatureComponent = ({ mobile }) => (
  <PhoneNumber number={mobile}></PhoneNumber>
)
